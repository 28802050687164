import React              from 'react'
import Helmet             from 'react-helmet'
import { Link }         from 'gatsby'

import config             from '../../../data/site-config'

import Layout             from '../../components/layout'
import MainWrapper        from '../../components/MainWrapper/MainWrapper'
import ServicePageHero    from '../../components/Services/ServicePageHero'
import ServicesPagination from '../../components/Services/ServicesPagination'

const WebsitesPage = () => (
  <Layout className="service-page content-page" style={{backgroundColor: '#1de9b6'}}>

    <Helmet
      title={`🕸 Websites • Responsive and Mobile Website Design • Spacetime`}
      meta={[
        { name: 'description', content: 'Stand out from the crowd with unique, business-centric mobile and responsive website design' }
      ]}
      link={[
        { rel: 'canonical', href: `${config.siteUrl}/services/websites/` }
      ]}
      bodyAttributes={{
        class: 'dark-theme'
      }}
    />

    <div>
      <ServicePageHero
        name={`websites`}
        title={`Websites`}
        description={`stand out from the crowd with stellar website design`}
        icon={`🕸`}
        iconPath={``}
        bgColor={`#1DE9B6`}
        textColor={`black`} />

      <MainWrapper>
        <section className="pv6">
          <div className="row narrow">
            <p>Everyone needs a website. Whether you are a solo entrepreneur or run a billion dollar business, you need a proper website that promotes your business and brand. Websites are often the first encounter many of your customers will see of you business and determine whether you are capable of handling their task at&nbsp;hand.</p>

            <h5 className="">What do you need?</h5>
            <ul className="system-sans o-70 lh-copy dark-purple f6 fw5 mv4">
              <li className="mv1">Increase sales and conversions</li>
              <li className="mv1">
                Responsive web design
                <ul>
                  <li className="mv1">Mobile web design</li>
                  <li className="mv1">Tablet web design</li>
                  <li className="mv1">Desktop web design</li>
                </ul>
              </li>
              <li className="mv1">Accessibility for impaired visitors</li>
              <li className="mv1">Search Engine Optimization (SEO)</li>
              <li className="mv1">Design worthy of your business</li>
              <li className="mv1">Editable content for clients</li>
              <li className="mv1">Guaranteed ROI (Return on Investment)</li>
              <li className="mv1">Social integration</li>
              <li className="mv1">Bank level security</li>
              <li className="mv1">Blogging</li>
              <li className="mv1">Online payments</li>
              <li className="mv1">eCommerce capabilities</li>
              <li className="mv1">Strategy for your online presence</li>
              <li className="mv1">Site analytics</li>
              <li className="mv1">Build email contact lists</li>
              <li className="mv1">Acquire domain name &amp; hosting</li>
            </ul>
          </div>
        </section>

        <section className="row pv4 pv5-ns mv5 bg-washed-yellow">
          <div className="tc">
            <h3 className="f2">Make your <span style={{color: '#1de9b6'}}>Website Design</span> work for you.</h3>
            <Link
              to="/contact/"
              className="ttu f7 fw8 tc link br1 ph5 pv2 pv3-ns dib mv4 dim white br-pill"
              style={{backgroundColor: '#1de9b6'}}>
                Contact us →
            </Link>
          </div>
        </section>

        <ServicesPagination
          link={`cto`}
          name={`CTO as a Service`}
          tagline={`hire technical leadership to grow your business`}
          color={`#ea5500`}
        />

      </MainWrapper>
    </div>
  </Layout>
)

export default WebsitesPage
