module.exports = {
  blogPostDir: "blog",
  siteTitle: "Spacetime, Design Experience Agency",
  siteTitleAlt: "Spacetime | Design, Web, Startups, Apps, WordPress | Dallas, TX",
  separator: "•",
  siteLogo: "./src/assets/images/favicon/favicon.png",
  siteUrl: "https://www.heyspacetime.com",
  pathPrefix: "/",
  siteDescription: "Spacetime is a Dallas-based digital experience agency specializing in web, app, startup, e-commerce and digital experience creation.",
  siteKeywords: "web, app, startup, design, experience design, design agency, digital agency, dallas, plano, texas, digital strategy, rails, react, react native, shopify",
  contactPhone: "972-366-5100",
  contactEmail: "hey@heyspacetime.com",
  contactAddress: "3740 N Josey Ln #240<br />Carrollton, TX 75007",
  contactAddressMap: "https://goo.gl/maps/25ivRBa66zCY9NP77",
  socialLinks: [
    {
      label: "Facebook",
      url: "https://www.facebook.com/heyspacetime",
      iconClassName: "fa fa-facebook",
      brandColor: "#3b5998"
    },
    {
      label: "Twitter",
      url: "https://twitter.com/heyspacetime",
      iconClassName: "fa fa-twitter",
      brandColor: "#1da1f2"
    },
    {
      label: "GitHub",
      url: "https://github.com/heyspacetime",
      iconClassName: "fa fa-github",
      brandColor: "#6cc644"
    },
    {
      label: "LinkedIn",
      url: "https://www.linkedin.com/company/hey-spacetime/",
      iconClassName: "fa fa-linkedin",
      brandColor: "#0077b5"
    },
    {
      label: "Dribbble",
      url: "https://dribbble.com/spacetime",
      iconClassName: "fa fa-dribbble",
      brandColor: "#ea4c89"
    }
  ],
  googleAnalyticsID: "UA-118963683-1",
  googleSearchConsole: "uZI_T83u7puGo4WeT62WIYI83Sr3P4I8kGhD6DGDbto",
  copyright: "Spacetime, LLC", // "Copyright © {currentYear}. {config.copyright}"
  themeColor: "#f15541", // Used for setting manifest and progress theme colors.
  backgroundColor: "#ffffff" // Used for setting manifest background color.
};
