import React   from 'react'
import { Link }         from 'gatsby'

const ServicesPagination = (props) => (
  <div className="row bt flex-ns">
    <Link to="/services/" className="db w-50-l pv4 pv5-m pv6-l ph2 ph3-m ph6-l black hover-bg-athens-gray br-ns">
      <span className="f6 fw5 mb2 dib o-50">Back to</span>
      <h5 className="ServiceCard__title ma0">All Services</h5>
    </Link>
    <Link
      bg={props.color}
      to={`/services/${props.link}/`}
      className="db w-50-l pv4 pv5-m pv6-l ph2 ph3-m ph6-l black hover-bg-athens-gray"
    >
      <span className="f6 fw5 mb2 dib o-50">Next Service</span>
      <h5 className="ServiceCard__title ma0">
        {props.name}&mdash;
        <span className="o-50">{props.tagline}</span>
      </h5>
    </Link>
  </div>
)

export default ServicesPagination
